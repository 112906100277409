@media (max-width: 576px) {
	body {
		font-size: 12px;
	}

	::-webkit-scrollbar {
		width: .2rem;
		height: .2rem;
	}

	::-webkit-scrollbar-thumb {
		background: #8b909e;
		border-radius: 10px;
	}

	::-webkit-scrollbar-track {
		background: #b8c0c8;
		border-radius: 10px;
	}

	.mobile-none {
		display: none;
	}

	.about-2shop {
		display: none;
	}

	/* Sidebar */

	.sidebar-active-left,
	.sidebar-left {
		left: 0px !important;
	}

	.sidebar-menu-scroll,
	.vertical-menu {
		bottom: 0;
		left: 0;
		width: 100%;
		height: 50px !important;
		padding: 0;
		background-color: #fff;
		display: flex;
		align-items: center;
		justify-content: center;
		border-top: 1px solid #ddd;
	}

	.sidebar-menu-scroll .nav-mini {
		display: flex;
		justify-content: center;
		gap: 30px;
	}

	.second-sidebar {
		margin-left: 0;
		width: 100%;
		height: calc(100% - 50px);
		box-shadow: none;
		left: 0;
	}

	.nav-child-mini.nav.nav-tabs {
		justify-content: center;
		gap: 10px;
	}

	.main-content {
		margin-left: 0 !important;
	}

	.main-content .page-content {
		padding: 70px 10px 60px 10px;
	}

	.filter {
		display: flex;
		flex-wrap: wrap;
	}

	.filter strong {
		display: block;
		width: 100%;
	}

	.pagination>li {
		margin-right: 0;
	}

	.table th,
	.table td {
		white-space: nowrap;
	}

	.table-responsive {
		max-height: 75vh;
	}

	.table table {
		position: relative;
		overflow: auto;
		z-index: 1;
	}

	.table th {
		position: sticky;
		top: -1px;
		z-index: 1;
		background-color: #fff;
	}

	.dropdown-filter.show {
		width: 100vw;
	}

	.dashboard-flex {
		flex-wrap: wrap;
	}

	.dashboard-card-mobile {
		width: 100% !important;
	}

	.dashboard-card .w-100 {
		width: 100% !important;
	}

	.dashboard-flex .w-15 {
		width: 100%;
	}

	.dashboard-card-items {
		display: flex;
		flex-wrap: wrap;
	}

	.dashboard-card-item {
		width: 50%;
	}

	.d-flex.justify-content-between.flex-wrap.mb-3 {
		row-gap: 10px;
	}

	.d-flex.flex-wrap {
		row-gap: 10px;
	}

	.mobile-wrap,
	.d-flex.mb-3,
	.d-flex.gap-3 {
		flex-wrap: wrap;
		row-gap: 10px;
	}

	h4 {
		font-size: 20px;
	}

	.auth-card {
		width: calc(100% - 20px);
		margin: 0 10px;
		top: 45%;
		margin: auto;
	}

	.auth-devider {
		display: none;
	}

	.navbar-phone {
		display: none;
	}

	#sidebar-menu {
		padding: 55px 0 30px 0;
	}

	.w-25 {
		width: 50% !important;
	}

	.w-15 {
		width: 25% !important;
	}

	.d-between {
		/* display: flex;
    justify-content: space-between;
    align-items: center; */
		flex-wrap: wrap;
		row-gap: 10px;
	}

	.call_center {
		display: none;
	}
}