.editor {
	display: flex;
	flex-direction: column;
	align-items: center;
}

/* Price Tag */
.price-tag {
	border: 1px solid #d1d1d1;
	position: relative;
	border-radius: 5px;
	background-color: #fff;
}

.price-tag-inputs {
	margin-bottom: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 10px;
}

.price-tag-input {
	width: 60px;
	border-radius: 10px;
	outline: none;
	border: 1px solid #ced4da;
	text-align: center;
}

.draggable-item.selected {
	background-color: #fff;
	color: var(--primary);
	width: fit-content;
}

/* Price Tag end */

/* Components */

.component-picker {
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.component-button {
	width: 100%;
	border-radius: 10px;
	height: 40px;
	outline: none;
	border: 1px solid #ced4da;
	background-color: var(--primary);
	color: #fff;
}

/* Components end  */

/* Controls */

.controls {
	background-color: #fff;
	height: 100%;
	padding: 16px;
}

.controls label {
	display: block;
	margin-bottom: 5px;
}

.controls .form-group {
	margin-bottom: 10px;
}

/* Controls end */