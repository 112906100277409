body.dark {
	--bg-color: #1b1b1d;
	--text-color: #fff;

	--header-bg-color: #242526;
	--header-text-color: #fff;
	--sidebar-bg-color: #242526;
	--sidebar-text-color: #d2d2d2;
	--sidebar-item-active-color: #ffffff0d;
	--sidebar-item-active-text-color: #fff;
	--card-bg-color: #282A36;

	color: #e3e3e3 !important;
	background-color: #1b1b1d !important;

	transition: 0.2s;
}

body.dark .card {
	background-color: #282A36;
}

body.dark .nav-link {
	color: #e3e3e3;
}

body.dark .nav-link.active,
body.dark .nav-tabs .nav-link:hover,
body.dark .nav-tabs .nav-link:focus {
	background: #ffffff0d;
	border: 1px solid transparent;
}

body.dark .table thead th,
body.dark .table tbody td {
	background-color: #1b1b1d;
	border-top-width: 0;
	border-bottom-width: 0;
	color: #d2d2d2;
}

body.dark .table-striped>tbody>tr:nth-of-type(odd)>* {
	background-color: #2B2B2D;
	border-top-width: 0;
	color: #d2d2d2;
}

body.dark label {
	color: #e3e3e3;
}

body.dark .input-group-text {
	background-color: #343434;
	color: #fff;
	border: 1px solid transparent;
}

body.dark .filter-block {
	background-color: #242526;
}

body.dark .form-control,
body.dark .css-13cymwt-control,
body.dark .auto-width-input {
	background-color: #242526;
	border: 1px solid #212121;
	color: #fff;
}

.dark .css-16xfy0z-control {
	background-color: #111111;
	border: 1px solid #111111;
	color: #fff;
}

body.dark .css-1dimb5e-singleValue {
	color: #fff;
}

body.dark {
	color: #fff;
}

body.dark .h1,
body.dark .h2,
body.dark .h3,
body.dark .h4,
body.dark .h5,
body.dark .h6,
body.dark h1,
body.dark h2,
body.dark h3,
body.dark h4,
body.dark h5,
body.dark h6 {
	color: #fff;
}

.dark .btn-outline-dark {
	border: 1px solid #e3e3e3;
	color: #e3e3e3;
}

.dark .table-control-icon {
	background-color: transparent;
	color: #fff;
}

body.dark .table tbody {
	border-top: none;
}

body.dark .modal-content {
	background-color: #1b1b1d;
	border: 1px solid transparent;
}

body.dark .new-card {
	background-color: var(--card-bg-color);
	color: #fff;
}

body.dark .dropdown-menu-list {
	background-color: var(--card-bg-color);
	border: 1px solid transparent;
}

.dark .modal-title,
.dark .btn-close {
	color: #fff;
}